import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import ProductItem from "./FrontEnd_ProductItem";
import Header from "./FrontEnd_Header";
import Footer from "./FrontEnd_Footer";
import Sidebar from "./FrontEnd_Sidebar";
import Search_Codigo from "./FrontEnd_Search_Codigo";
import Search_Descripcion from "./FrontEnd_Search_Descripcion";
import { useHistory } from "react-router-dom";
import { clear_cart, orden_compra, customer_note } from "../reducers/shoppingSlice";
import UserService from "../services/user.service";
import { Modal, Button } from '@mui/material';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';

import { styled } from '@mui/material/styles';
import RadioGroup, { useRadioGroup } from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import FooterText from "./FrontEnd_FooterText";

import { makeStyles } from '@mui/styles';

import { useFormik } from 'formik';
import * as yup from 'yup';
import { init_to_dir } from "../reducers/shoppingSlice";

import {
  Grid,
  ThemeProvider,
  StyledEngineProvider,
  adaptV4Theme,
} from "@mui/material";

const StyledFormControlLabel = styled((props) => <FormControlLabel {...props} />)(
  ({ theme, checked }) => ({
    '.MuiFormControlLabel-label': checked && {
      color: theme.palette.primary.main,
    },
  }),
);

//Validacion del formulario
const validationSchema1 = yup.object({
  direccion: yup
    .string('Direccion requerido')
    .required('Direcicon requerido'),
  codigopostal: yup
    .string('Codigo Postal requerido')
    //.min(8, 'Password should be of minimum 8 characters length')
    .required('Codigo Postal requerido'),
  localidad: yup
    .string('Localidad requerido ')
    //.min(8, 'Password should be of minimum 8 characters length')
    .required('Localida requerido'),
  predeterminada: yup
    .string('Localidad requerido ')
    //.min(8, 'Password should be of minimum 8 characters length')
    .required('Localida requerido'),
});

function MyFormControlLabel(props) {
  const radioGroup = useRadioGroup();

  let checked = false;

  if (radioGroup) {
    checked = radioGroup.value === props.value;
  }

  return <StyledFormControlLabel checked={checked} {...props} />;
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  container: {
    maxHeight: 440,
  },
  modal: {
    position: 'absolute',
    width: 400,
    backgroundColor: '#fff',
    border: '2px solid #000',
    boxShadow: 5,
    padding: (2, 4, 3),
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)'
  },
  iconos: {
    cursor: 'pointer'
  },
  inputMaterial: {
    width: '100%'
  }
}));

const Direccion = () => {
  const [botonDeshabilitado, setBotonDeshabilitado] = useState(false);


  //inicializacion de variables y validacion
  const formik1 = useFormik({
    initialValues: {
      direccion: '',
      codigopostal: '',
      localidad: '',
      provincia: '',
      contacto: '',
      destinofinal: '',
      predeterminada: '',
    },
    validationSchema: validationSchema1,
    onSubmit: (values) => {
      peticionPost1(values);
    },
  });

  const styles = useStyles();
  const classes = useStyles();

  const history = useHistory();
  const [data, setData] = useState([]);

  const dispatch = useDispatch();
  const state = useSelector((state) => state);
  const { iddireccion, minimo } = state;

  const { cart, orden_compra, customer_note } = state;

  const [modalEliminar, setModalEliminar] = useState(false);
  const [modalEditar, setModalEditar] = useState(false);
  const [iddireccion1, setIddireccion1] = useState(iddireccion);

  const [consolaSeleccionada, setConsolaSeleccionada] = useState({
    id: '',
    nombre: '',
    codigo: '',
  })

  const [predeterminada, setPredeterminada] = useState([
    { id: 'true', nombre: 'Si' },
    { id: 'false', nombre: 'No' },
  ]);

  const [direccion0, setDireccion0] = useState([
    {
      codigopostal: "",
      direccion: "Retira en Planta/Depósito - (Recordar presentarse con Art, casco y zapatos de seguridad)",
      i: 1,
      id: "0",
      localidad: "",
      predeterminada: "0",
      provincia: "",
      contacto: "",
      destinofinal: "",
    },
  ]);

  let order_total = 0;
  let total = 0;
  let bonificacion = 0;
  let bonificacion1 = 0;
  let bonificacion2 = 0;

  let order_total1 = 0;
  let order_total2 = 0;
  let order_total3 = 0;
  let order_total4 = 0;

  let iva = 0;
  let percepcion = 0;
  let ttemp = 0;

  let bbas = 0;
  let bgeneral = 0;
  let bcliente = 0;

  const [bonificaciont, setBonificaciont] = useState(0);
  const [ivat, setIvat] = useState(0);
  const [percepciont, setPercepciont] = useState(0);
  const [ttempt, setTtempt] = useState(0);
  const [showcartel, setShowcartel] = useState(false);

  cart.map(function (bal) {
    order_total = order_total + (bal.price * bal.quantity)
  });

  useEffect(() => {
    getTotal();
  }, []);


  const cerrarEditar = () => {
    history.push(process.env.PUBLIC_URL + "/gracias");
  }

  const abrirCerrarModalEditar = (id) => {
    getTotal();
    setModalEditar(!modalEditar);
  }

  const getDireccion = async (id) => {
    if (id) {
      const response = await UserService.getDireccionid(id);
      formik1.setValues(response);
    } else {
      formik1.resetForm();
    }
    setModalEditar(!modalEditar);
    getDirecciones();
  }

  const getTotal = async () => {
    try {
      const result = await UserService.getBonificaciones();

      if (result.data) {

        bonificacion = (order_total * result.data[0].extra_field_3) / 100;  //BAS
        order_total1 = order_total - bonificacion;
        bbas = result.data[0].extra_field_3;

        bonificacion1 = (order_total1 * result.data[0].adecuacion) / 100;   //Descuento General
        order_total2 = order_total1 - bonificacion1;
        bgeneral = result.data[0].extra_field_3;

        bonificacion2 = (order_total2 * result.data[0].descuento) / 100;    //Descuento de cada cliente
        order_total3 = order_total2 - bonificacion2;
        bonificacion = bonificacion + bonificacion1 + bonificacion2;
        setBonificaciont(bonificacion);
        bcliente = result.data[0].extra_field_3;


        order_total4 = order_total1 + order_total2 + order_total3;
        iva = ((order_total - bonificacion) * result.data[0].extra_field_1) / 100;
        setIvat(iva);
        percepcion = ((order_total - bonificacion) * result.data[0].extra_field_2) / 100;
        setPercepciont(percepcion);
        total = order_total - bonificacion;
        ttemp = total + iva;
        setTtempt(ttemp);

        if (ttemp >= minimo[0].minimo) {
          getDirecciones();
        } else {
          setData(direccion0);
          setShowcartel(true);
        }

      } else {
        //history.push("/login");
      }
    } catch (e) {
      console.log(e);
      history.push("/login");
    }
  }

  //trae lista de direcciones
  const getDirecciones = async () => {
    const result = await UserService.getDirecciones();
    setData(result.data);
  }

  const abrirCerrarModalEliminar = () => {
    setModalEliminar(!modalEliminar);
  }

  const peticionPost = async (values, orden_compra, customer_note) => {

    const result = await UserService.getBonificaciones();


    bonificacion = (order_total * result.data[0].extra_field_3) / 100; //BAS
    order_total1 = order_total - bonificacion;
    bbas = result.data[0].extra_field_3;

    bonificacion1 = (order_total1 * result.data[0].adecuacion) / 100;   //Descuento General
    order_total2 = order_total1 - bonificacion1;
    bgeneral = result.data[0].adecuacion;

    bonificacion2 = (order_total2 * result.data[0].descuento) / 100;  //Descuento de cada cliente
    order_total3 = order_total2 - bonificacion2;
    bonificacion = bonificacion + bonificacion1 + bonificacion2;
    bcliente = result.data[0].descuento;
    ///setBonificaciont(bonificacion);

    order_total4 = order_total1 + order_total2 + order_total3;
    iva = ((order_total - bonificacion) * result.data[0].extra_field_1) / 100;
    ///setIvat(iva);

    percepcion = ((order_total - bonificacion) * result.data[0].extra_field_2) / 100;
    ///setPercepciont(percepcion);

    total = order_total - bonificacion;
    ttemp = total + iva;
    ///setTtempt(ttemp);

    const response = await UserService.endPedido(values, orden_compra, customer_note, bonificacion, iva, percepcion, ttemp, total, bbas, bgeneral, bcliente, iddireccion1);
    ///setData(data.concat(response.data))
    if (values)
      dispatch(clear_cart());
    cerrarEditar()
  }

  const peticionPost1 = async (values) => {
    const response = await UserService.addmodDireccion(values.id, values);
    ///setData(data.concat(response.data))
    if (values.predeterminada == 'true')
      dispatch(init_to_dir(values.id));
    abrirCerrarModalEditar()
  }

  const seleccionarConsola = (consola, caso) => {
    setConsolaSeleccionada(consola);
    (caso === 'Insertar' || caso === 'Editar') ? abrirCerrarModalEditar(consola.id)
      : abrirCerrarModalEliminar(consola.id)

  }

  const clearCart = async (values) => {
    dispatch(clear_cart());
    history.push(process.env.PUBLIC_URL + "/home");
  }

  const handleRadioChange = (event) => {
    const selectedValue = event.target.value;
    setIddireccion1(selectedValue); // Actualiza el valor de iddireccion
  };

  const bodyEditar = (
    <div className={styles.modal}>
      <h3>Datos de la Direccion</h3>

      <form onSubmit={formik1.handleSubmit}>
        <Grid container spacing={3}>

          <Grid item xs={6}>
            <TextField
              name="direccion"
              label="Direccion"
              autoFocus={true}
              required
              value={formik1.values.direccion}
              onChange={formik1.handleChange}
              error={formik1.touched.direccion && Boolean(formik1.errors.direccion)}
              helperText={formik1.touched.direccion && formik1.errors.direccion}
            />
          </Grid>

          <Grid item xs={6}>
            <TextField
              name="codigopostal"
              label="Codigo Postal"
              required
              value={formik1.values.codigopostal}
              onChange={formik1.handleChange}
              error={formik1.touched.codigopostal && Boolean(formik1.errors.codigopostal)}
              helperText={formik1.touched.codigopostal && formik1.errors.codigopostal}
            />
          </Grid>

          <Grid item xs={6}>
            <TextField
              name="localidad"
              label="Localidad"
              required
              value={formik1.values.localidad}
              onChange={formik1.handleChange}
              error={formik1.touched.localidad && Boolean(formik1.errors.localidad)}
              helperText={formik1.touched.localidad && formik1.errors.localidad}
            />
          </Grid>

          <Grid item xs={6}>
            <TextField
              name="provincia"
              label="Provincia"
              required
              value={formik1.values.provincia}
              onChange={formik1.handleChange}
              error={formik1.touched.provincia && Boolean(formik1.errors.provincia)}
              helperText={formik1.touched.provincia && formik1.errors.provincia}
            />
          </Grid>

          <Grid item xs={6}>
            <TextField
              name="contacto"
              label="Contacto"
              value={formik1.values.contacto}
              onChange={formik1.handleChange}
              error={formik1.touched.contacto && Boolean(formik1.errors.contacto)}
              helperText={formik1.touched.contacto && formik1.errors.contacto}
            />
          </Grid>

          <Grid item xs={6}>
            <TextField
              name="destinofinal"
              label="Destino Final"
              value={formik1.values.destinofinal}
              onChange={formik1.handleChange}
              error={formik1.touched.destinofinal && Boolean(formik1.errors.destinofinal)}
              helperText={formik1.touched.destinofinal && formik1.errors.destinofinal}
            />
          </Grid>

          <Grid item xs={12}>
            <InputLabel htmlFor="outlined-age-native-simple">Predeterminada</InputLabel>
            <Select
              required
              native
              label="Predeterminada"
              value={formik1.values.predeterminada}
              onChange={formik1.handleChange}
              error={formik1.touched.predeterminada && Boolean(formik1.errors.predeterminada)}
              helperText={formik1.touched.predeterminada && formik1.errors.predeterminada}
              inputProps={{
                name: 'predeterminada',
                id: 'outlined-age-native-simple',
              }}
            >
              <option aria-label="None" value="" />
              {predeterminada.map((value) => (
                <option value={value.id} key={value.id}>
                  {value.nombre}
                </option>
              ))}
            </Select>
          </Grid>

          <Grid item xs={12}>
            <div class="col-lg-12" style={{ textAlign: "right" }} >
              <Button onClick={() => abrirCerrarModalEditar()}>Cancelar</Button>
              <button type="submit" class="btn btn-primary">Guardar</button>
            </div>
          </Grid>
        </Grid>
      </form>
    </div>
  )

  return (
    <>
      <Header />
      <Sidebar />
      <main id="main" class="main">

        <div class="pagetitle">
          <h1>ENVÍO</h1>
          <nav>
            <ol class="breadcrumb">
              <li class="breadcrumb-item"><a href="/">Inicio</a></li>
            </ol>
          </nav>
        </div>

        <section class="section dashboard">
          <div class="row">
            <div class="col-12">
              <div class="card top-selling overflow-auto">

                <div class="text-left">
                  <button onClick={() => seleccionarConsola(data, 'Insertar')} class="btn btn-primary">Nueva Direccion</button>
                </div>

                <div class="card-body pb-0">
                  <h5 class="card-title">Seleccionar el domicilio de envío<span></span></h5>

                  {showcartel && (
                    <div class="text-left">
                      <h5>Tu pedido no supera el mínimo requerido para el envío, podrás retirar en Planta, o seguir comprando o abonar flete de entrega...</h5>
                    </div>
                  )}

                  <RadioGroup name="use-radio-group" defaultValue={iddireccion} onChange={(e) => handleRadioChange(e)}>
                    {data.map((product) => (
                      <>
                        <MyFormControlLabel value={product.id} label={product.direccion + ' ' + product.codigopostal + ' - ' + product.localidad + ', ' + product.provincia + ', ' + product.contacto + ', ' + product.destinofinal} control={<Radio />} />
                      </>
                    ))}
                  </RadioGroup>

                  <table>
                    <tr>
                      <td>
                        <button onClick={() => clearCart()} class="btn btn-primary">LIMPIAR COMPRA</button>
                      </td>
                      <td>
 

                        <button
                        onClick={async () => {
                          setBotonDeshabilitado(true); // Deshabilitar el botón al hacer clic
                          try {
                            await peticionPost(cart, orden_compra, customer_note, iddireccion );
                          } catch (error) {
                            console.error('Error en la petición:', error);
                          }
                        }}
                        disabled={botonDeshabilitado}
                        class="btn btn-primary"
                        >
                          FINALIZAR COMPRA
                        </button>


                      </td>
                    </tr>
                  </table>

                  <br /><br />
                  <FooterText />

                </div>

              </div>

            </div>
          </div>
        </section>

      </main>

      <Modal
        open={modalEditar}
        onClose={abrirCerrarModalEditar}>
        {bodyEditar}
      </Modal>

      <Footer />



    </>
  );
};

export default Direccion;