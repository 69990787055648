import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import ProductItemExpress from "./FrontEnd_ProductItemExpress";
import Header from "./FrontEnd_Header";
import Footer from "./FrontEnd_Footer";
import Sidebar from "./FrontEnd_Sidebar";
import { add_to_cart } from "../reducers/shoppingSlice";
import UserService from "../services/user.service";
import { TextField } from '@mui/material';
import Total from "./FrontEnd_Total.component";
import { clear_cart, init_to_prod_quantity, init_to_prod, remove_one_from_cart, add_to_cart_quantity, add_quantity } from "../reducers/shoppingSlice";
import SnackbarMessage from './SnackbarMessage'; // Asegúrate de proporcionar la ruta correcta al archivo SnackbarMessage
import { useHistory } from "react-router-dom";

const PedidoExpress = (props) => {
    const dispatch = useDispatch();
    const [selectedProduct, setSelectedProduct] = useState(null);
    const [isCantidadEnabled, setCantidadEnabled] = useState(false);

    const cantidadRef = useRef(null); // Crear una referencia

    const state = useSelector((state) => state);
    const { cart } = state;

    let { id, product_sku, name, price, quantity } = cart;

    //let quantity2 = quantity;
    const [quantity2, setQuantity2] = useState(0);

    useEffect(() => {
        const GetData = async () => {
            try {
                const result = await UserService.getDeuda();
                document.getElementById('cod').focus();
                if (result.data) {
                    if (result.data === "true") {
                        setSelectedProduct(null);
                    }
                }
            } catch (e) {
                console.log(e);
            }
        }
        GetData();
    }, []);

    useEffect(() => {
        dispatch(init_to_prod(cart));
        dispatch(init_to_prod_quantity());
    }, []);

    useEffect(() => {
        cantidadRef.current.focus(); // Enfocar el campo cantidad cuando se habilite
        cantidadRef.current.select(); // Seleccionar el contenido del campo cantidad cuando se habilite
    }, [isCantidadEnabled]); // Ejecutar efecto cuando isCantidadEnabled cambie

    const handleChange = async (event) => {
        const searchValue = event.target.value;
        if (event.key === 'Enter') {
            const result = await UserService.getSearchArticulos(searchValue, 'cod');
    
            if (result.data && result.data.length > 0) {
                const product = result.data[0];
                setSelectedProduct(result.data);
                dispatch(init_to_prod(result.data));
                dispatch(init_to_prod_quantity());
                setCantidadEnabled(true); // Habilitar el campo de cantidad
    
                // Buscar si el producto ya está en el carrito
                const cartItem = cart.find((item) => item.id === product.id);
                if (cartItem) {
                    // Si el producto ya está en el carrito, mostrar su cantidad
                    setQuantity2(cartItem.quantity);
                } else {
                    // Si no está en el carrito, inicializar la cantidad en 1
                    setQuantity2(1);
                    // Agregar el producto al carrito con cantidad 1
                    dispatch(add_to_cart_quantity({
                        id: product.id,
                        name: product.name,
                        price: product.price,
                        product_sku: product.product_sku,
                        quantity: 1
                    }));
                    dispatch(add_quantity({ id: product.id, quantity: 1 }));
                }
            } else {
                setSelectedProduct(null);
                setCantidadEnabled(false); // Deshabilitar el campo de cantidad
                setQuantity2(0);
                handleShowMessage('<div><h4>El codigo ingresado no existe</h4></div>');
            }
        }
    };
    
    const handleChangeQuantity1 = (event) => {
        if (event.key !== 'Enter') return;
    
        const codInput = document.getElementById('cod');
        if (codInput) {
            codInput.value = '';
            codInput.focus();
        }
        
        if (selectedProduct) {
            const product = selectedProduct[0];
            if (quantity2 === 0) {
                // Si la cantidad es 0, eliminamos el producto del carrito
                dispatch(remove_one_from_cart(product.id));
            } else {
                // Si la cantidad no es 0, actualizamos o añadimos al carrito
                const newItem = {
                    id: product.id,
                    name: product.name,
                    price: product.price,
                    product_sku: product.product_sku,
                    quantity: quantity2
                };
                dispatch(add_to_cart_quantity(newItem));
                dispatch(add_quantity({ id: product.id, quantity: quantity2 }));
            }
        }
    
        setSelectedProduct(null);
        setCantidadEnabled(false);
        setQuantity2(0);
    };

    const handleChangeQuantity = (e) => {
        const value = parseInt(e.target.value);
        setQuantity2(value);
    
        if (!selectedProduct) return;
    
        const product = selectedProduct[0];
        const newItem = {
            id: product.id,
            name: product.name,
            price: product.price,
            product_sku: product.product_sku,
            quantity: value
        };
        dispatch(add_to_cart_quantity(newItem));
        dispatch(add_quantity({ id: product.id, quantity: value }));
    };

    //Para el uso del componente de mensajes 
    const history = useHistory();
    const [open, setOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const handleShowMessage = (message) => {
        setErrorMessage(message);
        setOpen(true);
    };
    const handleCloseMessage = () => {
        setOpen(false);
    };
    //Fin

    const delFromCart = (id, all = false) => {
        if (all) {
            dispatch(remove_one_from_cart(id));
        } else {
            dispatch(remove_one_from_cart(id));
        }
        redirectToHomeIfEmpty(); // Redirige al home si el carrito queda vacío después de eliminar un producto
    }

    // Función para redireccionar al home cuando el carrito quede vacío
    const redirectToHomeIfEmpty = () => {
        if (cart.length === 1) {
            history.push(process.env.PUBLIC_URL + "/home");
        }
    };

    const goCart = () => {
        history.push(process.env.PUBLIC_URL + "/cart");
    };

    const clearCart = async (values) => {
        dispatch(clear_cart());
        history.push(process.env.PUBLIC_URL + "/home");
    }

    return (
        <>
            <Header />
            <Sidebar />
            <main id="main" className="main">
                <div className="pagetitle">
                    <h1>PEDIDO EXPRESS</h1>
                    <nav>
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><a href="/">Inicio</a></li>
                        </ol>
                    </nav>
                </div>
                <nav>
                    <div className="col-12">
                        <div className="card top-selling overflow-auto">
                            <div className="card-body pb-0">
                                <div className="row mb-3">
                                    <div className="col-md-3">
                                        <p>Código</p>
                                        <input className="form-control col-6" id="cod" autoFocus placeholder="Código a buscar..." name="search" onKeyUp={handleChange} />
                                    </div>
                                    <div className="col-md-3">
                                        <p>Descripción</p>
                                        {selectedProduct && (
                                            <p>{selectedProduct[0].name}</p>
                                        )}
                                    </div>
                                    <div className="col-md-2">
                                        <p>Cantidad</p>
                                        <input
                                            className={`form-control col-6`}
                                            type="number"
                                            id="cant"
                                            name="cantidad" min="0"
                                            value={quantity2}
                                            onChange={handleChangeQuantity}
                                            onKeyUp={handleChangeQuantity1}
                                            disabled={!isCantidadEnabled}
                                            ref={cantidadRef} // Asignar la referencia al campo cantidad
                                        />
                                    </div>
                                    <div className="col-md-2">
                                        <p>Precio</p>
                                        {selectedProduct && (
                                            <p>{selectedProduct[0].price}</p>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </nav>
                <section className="section dashboard">
                    <div className="row">
                        <div className="col-12">
                            <div className="card top-selling overflow-auto">
                                <div className="card-body pb-0">
                                    <h5 className="card-title">Artículos</h5>
                                    <table className="table table-borderless">
                                        <thead>
                                            <tr>
                                                <th scope="col">Imagen</th>
                                                <th scope="col">Código</th>
                                                <th scope="col">Descripción</th>
                                                <th scope="col">Precio</th>
                                                <th scope="col">Cantidad</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {cart.map((product) => (
                                                <ProductItemExpress
                                                    key={product.id}
                                                    data={product}
                                                    delFromCart={delFromCart}
                                                />
                                            ))}
                                            <Total data={cart} />

                                            <tr>
                                                <td>
                                                </td>
                                                <td>
                                                    <table>
                                                        <tr>
                                                            <td>
                                                                <button onClick={() => clearCart()} className="btn btn-primary">LIMPIAR COMPRA</button>
                                                            </td>
                                                            <td>
                                                                <button onClick={() => goCart()} className="btn btn-primary">IR AL CARRITO</button>
                                                            </td>
                                                        </tr>
                                                    </table>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>

            <SnackbarMessage
                open={open}
                message={errorMessage}
                severity="success"
                onClose={handleCloseMessage}
            />

            <Footer />
        </>
    );
};

export default PedidoExpress;
