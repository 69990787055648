import React, { useState, useEffect, useRef } from "react";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import LinearProgress from "@mui/material/LinearProgress";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import { makeStyles } from "@mui/styles";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItemSecondaryAction from "@mui/material/ListItemSecondaryAction";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import Link from "@mui/material/Link";
import UserService from "../services/user.service";
import AuthService from "../services/auth.service";
import UploadService from "../services/user.service";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    height: 400,
    maxWidth: 400,
    backgroundColor: "#fff",
  },
}));

const AdminUploadFiles = ({ id, documento }) => {
  const classes = useStyles();
  const [selectedFiles, setSelectedFiles] = useState(undefined);
  const [progressInfos, setProgressInfos] = useState({ val: [] });
  const [message, setMessage] = useState([]);
  const [fileInfos, setFileInfos] = useState([]);
  const progressInfosRef = useRef(null);
  const [showClienteBoard, setShowClienteBoard] = useState(false);
  const [showAdminBoard, setShowAdminBoard] = useState(false);
  const [data, setData] = useState([]);

  useEffect(() => {
    const user = AuthService.getCurrentUser();
    if (user) {
      setShowClienteBoard(user.roles.includes("ROLE_USER"));
      setShowAdminBoard(
        user.roles.includes("ROLE_ADMINISTRADORES") ||
          user.roles.includes("ROLE_MASTER")
      );
    }

    UploadService.getFiles(id, documento).then((response) => {
      setFileInfos(response.data);
    });
  }, [id, documento]);

  const selectFiles = (event) => {
    setSelectedFiles(event.target.files);
    setProgressInfos({ val: [] });
    setMessage([]);
  };

  const upload = async (idx, file) => {
    try {
      await UploadService.upload(file, documento, (event) => {
        const _progressInfos = [...progressInfosRef.current.val];
        _progressInfos[idx].percentage = Math.round(
          (100 * event.loaded) / event.total
        );
        setProgressInfos((prevProgressInfos) => ({
          val: [...prevProgressInfos.val],
        }));
      });
      setMessage((prevMessage) => [
        ...prevMessage,
        "Archivo subido...: " + file.name,
      ]);
    } catch (error) {
      const _progressInfos = [...progressInfosRef.current.val];
      _progressInfos[idx].percentage = 0;
      setProgressInfos({ val: _progressInfos });
      setMessage((prevMessage) => [
        ...prevMessage,
        "No pudimos subir el archivo: " + file.name,
      ]);
    }
  };

  const eliminar = async (key, documento, id) => {
    const response = await UserService.delImage(id, documento, key);
    setData((prevData) => prevData.concat(response.data));

    UploadService.getFiles(id, documento).then((response) => {
      setFileInfos(response.data);
    });
  };

  const uploadFiles = () => {
    const files = Array.from(selectedFiles);

    const _progressInfos = files.map((file) => ({
      percentage: 0,
      fileName: file.name,
    }));

    progressInfosRef.current = {
      val: _progressInfos,
    };

    const uploadPromises = files.map((file, i) => upload(i, file));

    Promise.all(uploadPromises)
      .then(() => UploadService.getFiles(id, documento))
      .then((files) => {
        setFileInfos(files.data);
      });
  };

  return (
    <Paper>
      {progressInfos.val.length > 0 &&
        progressInfos.val.map((progressInfo, index) => (
          <Box display="flex" alignItems="center" key={index}>
            <Box width="100%" mr={1}>
              <LinearProgress variant="determinate" value={progressInfo.percentage} />
            </Box>
            <Box minWidth={35}>
              <Typography variant="body2" color="textSecondary">
                {`${Math.round(progressInfo.percentage)}%`}
              </Typography>
            </Box>
          </Box>
        ))}

      <div className="row my-3">
        {showAdminBoard && (
          <div className="col-8">
            <label className="btn btn-default p-0">
              <input type="file" multiple onChange={selectFiles} />
            </label>
          </div>
        )}

        {showAdminBoard && (
          <div className="col-4">
            <Button
              color="primary"
              type="submit"
              disabled={!selectedFiles}
              onClick={uploadFiles}
            >
              Subir archivo
            </Button>
          </div>
        )}
      </div>

      {message.length > 0 && (
        <div className="alert alert-secondary" role="alert">
          <ul>
            {message.map((item, i) => (
              <li key={i}>{item}</li>
            ))}
          </ul>
        </div>
      )}

      <div className={classes.root}>
        <List component="nav" aria-label="main mailbox folders" dense={true}>
          {fileInfos &&
            fileInfos.map((file, index) => (
              <ListItem key={index}>
                <Link href={file.url}>
                  {file.real ? (
                    <ListItemText primary={file.real} />
                  ) : (
                    <ListItemText primary={file.name} />
                  )}
                </Link>

                <ListItemSecondaryAction>
                  <IconButton edge="end" aria-label="delete">
                    <Link onClick={() => eliminar(file.name, documento, file.id)}>
                      {showAdminBoard && <DeleteIcon />}
                    </Link>
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
            ))}
        </List>
        <Divider />
      </div>
    </Paper>
  );
};

export default AdminUploadFiles;
