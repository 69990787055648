import React, { useState, useEffect } from 'react';
import UserService from "../services/user.service";

function DownloadLink({ url, fileName }) {
  const [fileExists, setFileExists] = useState(false);

  useEffect(() => {

    const checkFileExists = async () => {
        try {
  
          const result = await UserService.checkFile(url);
          if (result.exists) {
            setFileExists(result.exists);
          } else {
            setFileExists(false);
          }
        } catch (e) {
            setFileExists(false);
        }
      }
      checkFileExists();
  
  }, [url]);

  return (
    <div>
      {fileExists ? (
        <a href={url} target="_blank" rel="noopener noreferrer">
          <button type="button" className="btn btn-outline-primary">
            <i className="ri-download-2-line"></i> Descargar
          </button>
        </a>
      ) : (
        <button type="button" className="btn btn-outline-primary" disabled>
          <i className="ri-download-2-line"></i> {fileName} no disponible
        </button>
      )}
    </div>
  );
}

export default DownloadLink;
